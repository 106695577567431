import { present } from '../presents';
import { subscribeToProfileChange } from '../util/shared-core-v3';
import { socialApi } from '../adapter';
import type { AchievementPopupScreen } from '../types';
import type { AchievementPopupContainerProps } from '../presents/achievement-popup';

async function renderPopup(props: {
  slug: string;
  userId: number;
  newAchievement?: boolean;
  screen?: AchievementPopupScreen;
  trackingPointOfContact?: string;
  trackingProvider?: string;
}) {
  const AchievementPopup = (await import('../presents/achievement-popup/index'))
    .default;

  return new Promise<void>((resolve) => {
    const unmount = present<AchievementPopupContainerProps>(AchievementPopup, {
      ...props,
      onClose() {
        unmount();
        resolve();
      },
    });
  });
}

function open(props: {
  slug: string;
  userId: number;
  screen?: AchievementPopupScreen;
  trackingPointOfContact?: string;
  trackingProvider?: string;
}) {
  return renderPopup(props);
}

function subscribeToNewUserAchievement() {
  subscribeToProfileChange(async (profile) => {
    if (!profile?.last_obtained_achievement) {
      return;
    }

    try {
      renderPopup({
        slug: profile.last_obtained_achievement.slug,
        userId: profile.id,
        newAchievement: true,
        trackingPointOfContact: 'obtained_achievement',
        trackingProvider: window.location.pathname,
      });
      socialApi.markAchievementAsShown(profile.last_obtained_achievement.slug);
    } catch (e) {
      console.error(e);
    }
  });
}

export const achievementPopup = {
  open,
  subscribeToNewUserAchievement,
};
