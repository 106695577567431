import type { SocialApiAdapter } from '../social-api';
import achievementResponse from './data/achievements.json';
import featureFlagsResponse from './data/feature-flags.json';
import type { components } from '../types/social-api-types';

async function getUserAchievementBySlug() {
  return Promise.resolve(
    achievementResponse as components['schemas']['ProfileAchievementDetail']
  );
}

async function markAchievementAsShown() {
  return Promise.resolve();
}

async function getFeatureFlags() {
  return Promise.resolve(
    featureFlagsResponse as components['schemas']['FeatureFlagsResponse']
  );
}

const mockSocialAdapter: SocialApiAdapter = {
  getUserAchievementBySlug,
  markAchievementAsShown,
  getFeatureFlags,
};

export default mockSocialAdapter;
