import { mercuryApi } from '../util/http-api';
import type { components } from './types/mercury-api-types';

export type FlowBySlug = components['schemas']['Flow'] &
  NonNullable<Pick<components['schemas']['Flow'], 'image'>>;

// * Принимает slug'и через запятую
async function getFlowsImagesBySlug(slugsString: string) {
  let response;
  try {
    response = await mercuryApi.get<FlowBySlug[]>(
      `/flows/?slugs=${slugsString}&fields=kind,image&limit=300`
    );
  } catch (err) {
    console.error(err);
  }

  return (
    response?.reduce((acc, item) => {
      if (item.slug) {
        acc[item.slug] = item.image?.files.original.filepath;
      }
      return acc;
    }, {} as Record<string, string | undefined>) ?? {}
  );
}

async function getArticleCardsBySlugs(
  slugs: string[],
  sortByPublicationDate: 'oldest' | 'newest' = 'oldest'
): Promise<components['schemas']['FlowCard'][]> {
  const params = new URLSearchParams({
    slugs: slugs.join(','),
    limit: slugs.length.toString(),
    orderby:
      sortByPublicationDate === 'oldest' ? 'date_published' : '-date_published',
  });

  try {
    const response = await mercuryApi.get<
      components['schemas']['FlowCardCachedList']
    >(`/articles/cards?${params.toString()}`, true);
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

const mercuryApiAdapter = {
  getFlowsImagesBySlug,
  getArticleCardsBySlugs,
};

export default mercuryApiAdapter;

export type MercuryAdapter = typeof mercuryApiAdapter;
