import { socialApi } from '../util/http-api';
import type { components } from './types/social-api-types';

async function getUserAchievementBySlug(userId: number, slug: string) {
  return socialApi.get<components['schemas']['ProfileAchievementDetail']>(
    `/profiles/${userId}/achievements/${slug}/`
  );
}

async function markAchievementAsShown(slug: string) {
  return socialApi.post(`/account/achievements/${slug}/mark_as_shown/`, {});
}

async function getFeatureFlags() {
  return socialApi.get<components['schemas']['FeatureFlagsResponse']>(
    '/flags/'
  );
}

const socialApiAdapter = {
  getUserAchievementBySlug,
  markAchievementAsShown,
  getFeatureFlags,
};

export default socialApiAdapter;

export type SocialApiAdapter = typeof socialApiAdapter;
